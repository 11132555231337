import React, { useState } from "react";
import Session from "../../../models/Session/Session";
import HeatMap from "./HeatMap/heatmap";
import styles from "./OrderDetails.module.css";
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import {
  analyticsConfig,
  trackAnalytics,
} from "../../../utils/analyticsConfig";

const OrderDetails: React.FC<{ orderDetails: Session }> = (props) => {
  const { t } = useTranslation();

  const [modalImage, setModalImage] = useState<string>("");
  const [modalURL, setModalURL] = useState<string>("");
  const [clicked, setClicked] = useState<boolean>(false);

  const openModal = (name: string, url: string) => {
    setModalImage(name);
    setModalURL(url);
    setClicked(true);
    trackAnalytics(analyticsConfig.Exam.modalOpen, {
      image: name,
      session: props.orderDetails.externalId,
    });
  };

  return (
    <>
      <div className={styles.Header}>
        <div className={styles.Order}>
          <p>{t("licenceId")}</p>
          <p>{props.orderDetails.licenceId}</p>
        </div>
        <div className={styles.Order}>
          <p>{t("licenseName")}</p>
          <p>{props.orderDetails.licenseName}</p>
        </div>
        <div className={styles.Order}></div>
        <div className={styles.Info}>
          <p>{t("diagnosticResult")}</p>
          <p>{props.orderDetails.diagnosticResult}</p>
        </div>
      </div>
      {props.orderDetails.submissions.map((submission) => (
        <div className={styles.Body} key={submission.timestamp}>
          <div className={styles.Side}>
            <h3>{t("analysisOutput")}</h3>
            <p>{submission.analysisResult}</p>
          </div>
          <div className={styles.Main}>
            <div className={styles.Timestamp}>{submission.timestamp}</div>
            <div className={styles.Images}>
              {submission.images.map((image) => (
                <React.Fragment key={image.name}>
                  <div className={styles.Image}>
                    <div className={image.valid ? "" : styles.Red}>
                      <p>{image.name}</p>
                    </div>
                    <img
                      src={image.url}
                      className={image.valid ? "" : styles.Border}
                      onClick={() => {
                        openModal(image.name, image.url);
                      }}
                      alt=""
                    />
                    {image.orientation != null && image.fixation != null && (
                      <div className={image.valid ? "" : styles.Red}>
                        <p>
                          {image.orientation}{" "}
                          {image.fixation.replace("Centered", "")}
                        </p>
                        <p></p>
                        <p>
                          {`Quality Index: ${image.imageQualityIndex.toFixed(
                            2
                          )}`}
                        </p>
                      </div>
                    )}
                  </div>
                  {clicked ? (
                    <HeatMap
                      imageName={modalImage}
                      imageURL={modalURL}
                      modalClicked={modalImage === image.name}
                      onClose={() => {
                        setClicked(false);
                      }}
                    />
                  ) : (
                    <div></div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default OrderDetails;
