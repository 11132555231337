import React, { useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "./App.css";
import Layout from "./hoc/Layout/Layout";
import Licenses from "./containers/Licenses/Licenses";
import Exams from "./containers/Exam/Exam";
import Result from "./containers/Result/Result";
import Login from "./containers/Login/Login";
import Logout from "./containers/Logout/Logout";
import * as actions from "./store/actions/";
import { connect } from "react-redux";
import { ThemeProvider } from "@ddx/component-library";

const App: React.FC<{
  isAuthenticated: boolean;
  onCheckAuthState: () => void;
}> = (props) => {
  useEffect(() => {
    props.onCheckAuthState();
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider>
        <Layout>
          {props.isAuthenticated ? (
            <>
              <Route path="/login" component={Login} exact />
              <Route path="/logout" component={Logout} exact />
              <Route path="/licenses" component={Licenses} exact />
              <Route path="/exams/:sessionId" component={Exams} exact />
              <Route path="/result/:id" component={Result} exact />
            </>
          ) : (
            <Route path="/" component={Login} />
          )}
        </Layout>
      </ThemeProvider>
    </BrowserRouter>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isAuthenticated: state.auth.user !== null,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onCheckAuthState: () => dispatch(actions.checkAuthState()),
    onLogin: (code: string) => dispatch(actions.login(code)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
