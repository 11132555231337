import * as actionTypes from './actionTypes';
import UserPool from '../../UserPool'
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js'
import i18n from '../../translations/i18n'
import axios from 'axios';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  }
}

export const authSuccess = (user: any) => {
  localStorage.setItem('token', user.access_token)
  localStorage.setItem('expireAt', (Date.now() + user.expires_in * 1000) + '')

  return {
    type: actionTypes.AUTH_SUCCESS,
    user: user
  }
}

export const authFail = (error: any) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error ?? { data: { message: 'Connection error' } }
  }
}

export const authLogout = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('expireAt')

  return {
    type: actionTypes.AUTH_LOGOUT
  }
}

export const reset = () => {
  return {
    type: actionTypes.AUTH_RESET
  }
}

export const checkAuthTimeout = (expirationTime: string) => {
  const expireInMilliseconds = (new Date(parseInt(expirationTime)).getTime() - new Date().getTime());
  
  return (dispatch: (action: { type: string }) => void) => {
    setTimeout(() => {
      dispatch(authLogout());
    }, expireInMilliseconds)
  }
}

export const login = (code: string | string[]) => {
  return (dispatch: any) => {
    dispatch(authStart());
    const redirect_uri = window.location.origin
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      params: {
        grant_type: 'authorization_code',
        code,
        client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
        redirect_uri
      }
    }

    axios.post(process.env.REACT_APP_TOKEN_ENDPOINT as string, null, config)
    .then((response) => {
      dispatch(authSuccess(response.data))
      dispatch(checkAuthTimeout((Date.now() + (response.data.expires_in * 1000)) + ''))
    })
    .catch((error) => {
      dispatch(authLogout());
    })
  
  }
}

export const checkAuthState = () => {
  return (dispatch: any) => {
    const token = localStorage.getItem('token')
    const expireAt = localStorage.getItem('expireAt')    

    if (!token || !expireAt) {
      dispatch(authLogout());
    } else {      
      const expirationDate = new Date(parseInt(expireAt))
      if (expirationDate > new Date()) {
        dispatch(authSuccess({access_token: token, expires_in: timestampToExpire(expireAt)}));
        dispatch(checkAuthTimeout(expireAt))
      } else {
        dispatch(authLogout());
      } 
    }
  }
}

// Convert a js timestamp into seconds remaining until token expiration
const timestampToExpire = (timestamp: string) => {
  const diff = Math.abs((new Date()).getTime() - (new Date(parseInt(timestamp))).getTime())

  return Math.floor(diff/1000)
}