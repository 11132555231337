const id = 'ExamReview:';

export const analyticsConfig =
{
  Exam: {
    modalOpen: `${id}modal_open`,
  }
};

export const trackAnalytics = (event: any, capturedData: any) => (window as any)?.analytics && (window as any).analytics.track(event, capturedData, {})

export const identifyAnalytics = (userId: any) => (window as any)?.analytics && (window as any).analytics.identify(userId, null, {})

export const resetAnalytics = () => (window as any)?.analytics && (window as any).analytics.reset()
