import React from 'react'
import Table from '../../components/Table/Table'
import Pagination from '@material-ui/lab/Pagination'
import styles from '../Containers.module.css'
import { Grid } from '@material-ui/core'

const Licenses: React.FC = () => {
    return (
        <div className={styles.Container}>
            <Table/>
            <Grid container justify = "center">
                <Pagination count={10} />
            </Grid>
        </div>
    )
}

export default Licenses